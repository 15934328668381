import React from 'react'
import { TwitterTimelineEmbed } from 'react-twitter-embed'

export default function TwitterFeed () {
  return (
    <>
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName="ICPEconf"
        autoHeight
      />
    </>
  )
}
