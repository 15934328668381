import React from 'react'
import { Col, Row } from 'antd'

const Sponsors = () => (
  <Row align='middle'>
    <Col span={14} align='middle'>
      <img
        src='https://www-file.huawei.com/-/media/corp/home/image/logo_400x200.png'
        alt='Huawei logo'
        style={{ maxWidth: '100%' }}
      />
    </Col>
  </Row>
)

export default Sponsors
