import React, { useEffect } from 'react';
import { Col, Row, Typography } from 'antd'
import { graphql } from 'gatsby'
import iframeResize from 'iframe-resizer/js/iframeResizer';

import SEO from '../components/seo'
import Layout from '../components/layout/layout'
import TwitterTimeline from '../components/twitter-timeline'
import Logos from '../components/logos'
import Sponsors from '../components/sponsors'

export default function Template ({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  useEffect(() => {
    

    // Met à jour le titre du document via l’API du navigateur
    //document.title = `Vous avez cliqué ${count} fois`;
  var myIframe = document.querySelector('#inlineFrameExampleSchedule');
  if (myIframe != null){
      iframeResize({ log: false , checkOrigin: false}, '#inlineFrameExampleSchedule')    
  }
  var myIframe1 = document.querySelector('#inlineFrameExample');
  if (myIframe1 != null){
      iframeResize({ log: true , checkOrigin: false}, '#inlineFrameExample')    
  }
  var myIframe2 = document.querySelector('#inlineFrameExample1');
  if (myIframe2 != null){
      iframeResize({ log: false, checkOrigin: false }, '#inlineFrameExample1')    
  }
  });
  return (
    <Layout>
      <SEO title={`ICPE 2021 - ${frontmatter.title}`} />

      <section className='container'>
        <Row gutter={32}>
          <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>

            <Typography.Title level={1}>{frontmatter.title}</Typography.Title>
            <div
              className='blog-post-content'
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </Col>

          <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
            <Logos />
            <Typography.Title level={2}>Gold sponsors</Typography.Title>
            <Sponsors />
            <Typography.Title level={2}>Twitter feed</Typography.Title>
            <TwitterTimeline />
          </Col>
        </Row>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        menu
        menuPosition
      }
    }
  }
`


if (typeof window !== 'undefined') {
  window.addEventListener('scroll', function(event){
  var myIframe = document.querySelector('#inlineFrameExampleSchedule');
  if (myIframe != null){
      var topOffset = myIframe.getBoundingClientRect().top + window.scrollY;
      var currentScroll = document.scrollingElement.scrollTop;
      myIframe.contentWindow.postMessage(topOffset + ':' + currentScroll, '*');
  }
});
}



