import React from 'react'
import { Col, Row } from 'antd'

const Logos = () => (
  <Row align='middle'>
    <Col span={7}>
      <img
        src='https://pbs.twimg.com/profile_images/378800000127113121/454150918d728106be0670e6111a6b4b.jpeg'
        alt='ACM logo'
        style={{ maxWidth: '100%' }}
      />
    </Col>
    <Col span={14} offset={2}>
      <img
        src='https://icpe2020.spec.org/assets/logo_specrg.png'
        alt='SPEC logo'
      />
    </Col>
  </Row>
)

export default Logos
